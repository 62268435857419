import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    Si estàs preparant-te per accedir al Cos de Mossos d'Esquadra, tenim el llibre
                    que et pot ajudar a superar la primera prova amb garanties:{" "}
                    <em>
                        Les 500 preguntes imprescindibles per accedir al Cos de Mossos d'Esquadra
                    </em>
                    .
                </p>

                <p>
                    Aquest llibre és una eina essencial per a qualsevol opositor, ja que recull les
                    500 preguntes clau que has de dominar sí o sí. Amb ell, podràs establir una base
                    sòlida per al teu estudi i afrontar el procés amb la confiança necessària per
                    superar amb èxit la prova de coneixements.
                </p>

                <p>
                    Està pensat per ajudar-te a organitzar el teu aprenentatge de manera clara i
                    efectiva, oferint-te una guia estructurada per repassar els conceptes més
                    importants que apareixen habitualment als exàmens.
                </p>

                <p>
                    Amb aquest llibre, no només guanyaràs seguretat, sinó que estaràs millor
                    preparat per a qualsevol pregunta que pugui sorgir durant l'oposició. Es tracta
                    de l'eina ideal per reforçar el teu coneixement i assegurar-te que estàs un pas
                    més a prop del teu objectiu.
                </p>

                <p class="font-semibold">
                    Compra el llibre ara i comença a preparar-te amb el suport que necessites per
                    aconseguir el teu somni!
                </p>

                <div>
                    <strong>Com accedir al mòdul d'iMossos?</strong>
                    <br />

                    <ul className="mt-2">
                        <li>1. Descarrega l'aplicació.</li>
                        <li>
                            2. Inicia sessió amb el mateix correu que vas fer la compra del llibre.
                        </li>
                        <li>3. Dintre l'aplicació, trobaràs el mòdul actiu.</li>
                    </ul>
                </div>

                <div>
                    <strong>
                        Com accedir al mòdul d'iMossos, si ja estic registrat amb un altre correu?
                    </strong>
                    <br />
                    Contacta amb nosaltres a través de{" "}
                    <a
                        href="mailto:info@iopos.academy"
                        className="text-cpurple font-semibold hover:text-ccyan duration-300"
                    >
                        info@iopos.academy
                    </a>{" "}
                    i t'ajudarem a activar el teu mòdul.
                </div>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/ZefgSQzwqkQ"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Assegura una base sòlida per al teu estudi i afronta la primera prova amb confiança."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
